import React from 'react'
import '../line_menu/lineMenu.css'
import {GiHamburgerMenu} from 'react-icons/gi';
import {IoCloseOutline} from 'react-icons/io5'

export default function LineMenu({menuStatus, showMenu}) {

const [menu, setMenu] = React.useState(<GiHamburgerMenu/>)

React.useEffect(()=>{
  if(window.innerWidth < 751){
    if(!menuStatus) setMenu(<GiHamburgerMenu/>)
    else setMenu(<IoCloseOutline />)
  }
}, [menuStatus])

return (
    <div className="menu">
      <div className="container">
        <div className="menu__title">
          <div className="menu_burger" onClick={showMenu}>
              {menu}   
            <div className='menu_burger_text'>Меню</div>
          </div>
          <div className="menu_list">
          <div className="menu_list_item">+7(986)765-94-80</div>
            <div className="menu_list_item">spry1@yandex.ru</div>
          </div>
        </div>
      </div>
    </div>
  )
}
