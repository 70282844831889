import React from 'react'
import '../footer/footer.css'

export default function Footer() {
  return (
    <footer>
             <div className="container">
                <div className="footer_content">
                    <div className="footer_item">
                        <h3>Реквизиты</h3>
                        <div className="rexisites_title">ООО "Крюс"</div>
                        <div className="reg_num"><span>ИНН:</span>5262385886</div>
                        <div className="reg_num"><span>ОГРН:</span>1225200015985</div>

                        <div className="footer_block founder">
                            <div className="footer_block_title">Руководитель</div>
                            <div className="footer_block_item">Потапов Сергей Валерьевич</div>
                        </div>

                        <div className="footer_block legal_adress">
                            <div className="footer_block_title">Юридический адрес</div>
                            <div className="footer_block_item">603105,Нижегородская область,г. Нижний Новгород, ул. Чачиной, д. 39, помещ. 3 к. 1</div>
                        </div>

                        <div className="footer_block post_adress">
                            <div className="footer_block_title">Почтовый адрес</div>
                            <div className="footer_block_item">603105,Нижегородская область,г. Нижний Новгород, ул. Чачиной, д. 39, помещ. 3 к. 1</div>
                        </div>
                    </div>

                    <div className="footer_item">
                        <h3>Офис</h3>
                        <div className="footer_block office_adress">
                            <div className="footer_block_title">Адрес</div>
                            <div className="footer_block_item">603105,Нижегородская область,г. Нижний Новгород, ул. Чачиной, д. 39, помещ. 3 к. 1</div>
                        </div>

                        <div className="footer_block working_mode">
                            <div className="footer_block_title">Режим работыс</div>
                            <div className="footer_block_item">с 9-00 до 18-00</div>
                            <div className="footer_block_item">Часы приема: с 16-00 до 18-00</div>
                        </div>

                        <div className="footer_block phone">
                            <div className="footer_block_title">Телефоны</div>
                            <div className="footer_block_item">+7(986)765-94-80</div>
                        </div>

                        <div className="footer_block mail">
                            <div className="footer_block_title">Электронная почта</div>
                            <div className="footer_block_item">spry1@yandex.ru</div>
                        </div>
                    </div>


                    <div className="footer_item">
                        <h3>Диспетчерская</h3>
                        <div className="footer_block phone">
                            <div className="footer_block_title">Телефоны</div>
                            <div className="footer_block_item">+7(986)765-94-80</div>
                        </div>

                        <div className="footer_block working_mode">
                            <div className="footer_block_title">Режим работыс</div>
                            <div className="footer_block_item">с 9-00 до 18-00</div>
                        </div>

                        <div className="footer_block control_adress">
                            <div className="footer_block_title">Адрес</div>
                            <div className="footer_block_item">603105,Нижегородская область,г. Нижний Новгород, ул. Чачиной, д. 39, помещ. 3 к. 1</div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
  )
}
