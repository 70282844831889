import React from 'react'

export default function IndexPage({ files }) {

    // const pageFiles = files.map(({ docTitle, fileLink, fileName }, index) => {
    //     return <PageFiles key={index} docTitle={docTitle} fileLink={fileLink} fileName={fileName} />
    // })

    return (
        <div>
            <div className="page_titile">
                <h1>Управляющая компания</h1>
                <h2>Общество с ограниченной ответственностью</h2>
                <h2>"Крюс"</h2>
            </div>
            <div className="page_subtitle">
                <h2>Здравствуйте, уважаемые жильцы!</h2>
                <h2>Рады приветствовать вас на сайте нашей управляющей компании!</h2>
                <p>Здесь вы найдёте всю необходимую информацию, подлежащую раскрытию, согласно Стандарта раскрытия информации (Постановления Правительства №731 от 23 сентября 2010).
                </p>
            </div>
            <div style = {{textAlign: 'center'}} >Нет договоров  </div>
            {/* {pageFiles} */}
        </div>
    )
}
// function PageFiles({ docTitle, fileLink, fileName }) {
//     return (
//         <div className="page_files">
//             <div className="files_title">
//                 <div className="files_title_text">{docTitle}</div>
//             </div>
//             <div className="files_available">
//                 <a href={fileLink}> {fileName}</a>
//             </div>
//         </div>)
// }